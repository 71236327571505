import React, { useEffect  } from 'react';

const AdText = (props) => {
    const { id } = props;  
    
    const adTextStyle = {
        display: "table", 
        textAlign: "left",
        color: "lightgray",
        position: "relative",
        marginTop: "8px",
        marginBottom: "4px"
    }

    return (
       <h4 id={'adText-' + id} style={adTextStyle}>Advertisement</h4>
    );
};

const Ad = (props) => {
    const { id, dataAdSlot, height, width } = props;  
    const isResponsive = !height && !width

    useEffect(() => {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {}

    },[]);
    
    return (
        <>
            <ins id={'ad-' + id}
                className="adsbygoogle"
                style={
                    { 
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width, 
                    height
                    }}
                data-ad-client="ca-pub-9122012703187454"
                data-ad-slot={dataAdSlot}
                data-ad-format={isResponsive? "auto" : undefined}
                data-full-width-responsive={isResponsive? "true" : undefined}>
            </ins>
        </>
    );
};

const AdsComponent = (props) => {
    const { dataAdSlot, width, height, className: adsContainerClassNames} = props;
    const id = crypto.randomUUID();

    useEffect(() => {
        const ad = document.getElementById('ad-' + id);
        const adText = document.getElementById('adText-' + id);
        if (!ad.style.height) {
            adText.style.display = 'none';
        }
    },[]);
    
    return (
        <div className={adsContainerClassNames}>
            <AdText id={id} />
            <Ad id={id} dataAdSlot={dataAdSlot} width={width} height={height} />
        </div>
    );
};

export default AdsComponent;