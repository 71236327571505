import React, { Component, useState } from "react"
import { connect } from "react-redux"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"

const styles = theme => ({
  body: {
    fontFamily: 'Mastro Sans DEMO Regular, sans-serif',
  },
  h3: {
    fontWeight: 'normal',
  },
  faq: {
    margin: '10px',
  },
  question: {
    padding: '10px',
    backgroundColor: 'white',
    marginBottom: '10px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, border-color 0.3s',
    "&:hover": {
      backgroundColor: "#f0f0f0",
      borderColor: 'blue',
      "& $plusCircle": {  // Selecting plusCircle when question is hovered
        backgroundColor: 'blue',
        borderColor: 'blue',
      },
    },
  },
  answer: {
    display: 'block',
    marginTop: '5px',
    backgroundColor: '#F9F9F9',
    marginBottom: '10px',
    padding: '10px',
    textAlign: 'left',
    borderRadius: '0 0 5px 5px',
    '& p': {
      margin: '0',
    },
  },
  plusCircle: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    backgroundColor: 'white',
    border: '2px solid #ddd',
    borderRadius: '50%',
    marginLeft: '10px',
    transition: 'background-color 0.3s, border-color 0.3s',
  },
  plusSign: {
    display: 'block',
    color: '#ddd',
    textAlign: 'center',
    lineHeight: '18px',
  },
  active: {
    padding: '10px',
    backgroundColor: 'white',
    cursor: 'pointer',
    borderColor: 'blue',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px solid #ddd',
    borderRadius: '10px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
    transition: 'background-color 0.3s, border-color 0.3s',
    '& $plusCircle': {
      backgroundColor: 'blue',
      borderColor: 'blue',
    },
    '& $plusSign': {
      color: 'white',
    },
  },
})

class FaqItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isActive: false,
    };
  }

  toggleAnswer = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  };

  render() {
    const { classes, question, answer } = this.props
    const { isActive } = this.state;

    return (
      <div>
        <Paper className={isActive ? classes.active : classes.question} onClick={this.toggleAnswer}>
            <Typography variant="h6">{question}</Typography>
            <div className={classes.plusCircle}>
            <div className={classes.plusSign}>+</div>
            </div>
        </Paper>
        {isActive && (<div className={classes.answer}>
            <Typography><b>Answer:</b> {answer}</Typography>
        </div>)}
    </div>
    )
  }
}

const mapStateToProps = state => {
    return {}
  }
  
const mapDispatchToProps = dispatch => {
return {}
}

export default withStyles(styles)(
connect(
    mapStateToProps,
    mapDispatchToProps
)(FaqItem)
)